<template>
    <!-- <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Reportes</h2>
        </div>
    </div> -->

    <section class="content">
        <h2 class="has-text-primary-dark">Reportes</h2>

        <div class="tile-ancestor">
            <div class="tile is-vertical">
                <div class="tile is-parent">
                    <article class="tile is-child box is-relative">
                        <b-loading :is-full-page="false" v-model="isLoading.historial" />
                        <line-chart label="Documentos Firmados" />
                    </article>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LineChart from '@/components/Home/LineChart.vue';
import Message from '@/utils/Message';

export default {
    metaInfo: {
        title: 'Reportes'
    },
    components: {
        LineChart
    },
    data() {
        return {
            isLoading: {
                historial: false
            },
        }
    },
    mounted() {
        this.fetchHistorial();
    },
    computed: {
        currentPerfil() {
            return this.$store.state.me.perfil;
        },
        stats() {
            return this.$store.state.empresas.stats;
        },
    },
    methods: {
        async fetchHistorial(cantidadDias = 7) {
            this.isLoading.historial = true;
            try {
                const res = await this.$store.dispatch(
                    'documentos/getStats',
                    cantidadDias
                );
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isLoading.historial = false;
            }
        }
    }
}
</script>

<style>

</style>
