<script>
import { Line } from 'vue-chartjs';

export default {
    extends: Line,
    props: {
        label: String,
    },
    computed: {
        historial() {
            return this.$store.state.documentos.stats.cantidades;
        },
    },
    watch: {
        historial: function (historial) {
            const labels = historial.map(h => h.fecha);
            const cantidades = historial.map(h => h.cantidad);
            this.renderChart(
                {
                    labels: labels,
                    datasets: [
                        {
                            label: this.label,
                            data: cantidades,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1,
                        },
                    ],
                },
                { responsive: true, maintainAspectRatio: false }
            );
        },
    },
};
</script>

<style></style>
